import Vue from "vue";
import VueRouter from "vue-router";
import UserPage from "../views/UserPage.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "user",
        component: UserPage,
    },
    {
        path: "/business",
        name: "business",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/BusinessPage.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/PrivacyPage.vue"),
    },
    {
        path: "/fansleep",
        name: "fansleep",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/FansleepPage.vue"),
    },
    {
        path: "/subscription",
        name: "subscription",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/SubscriptionPage.vue"),
        children: [{
                path: "reject",
                name: "subscription",
                component: () =>
                    import ('../components/subscription/SubscribeRejection.vue'),

                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: 'month',
                name: "subscription",
                component: () =>
                    import ('../components/subscription/SubscriptionMonth.vue'),


                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: 'year',
                name: "subscription",
                component: () =>
                    import ('../components/subscription/SubscriptionYear.vue'),


                meta: {
                    requiredAuth: true,
                },
            },
            {
                path: 'history',
                name: "subscription",
                component: () =>
                    import ('../components/subscription/SubscribeHistory.vue'),


                meta: {
                    requiredAuth: true,
                },
            },
        ]
    },
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return { selector: to.hash };
        }
        return { x: 0, y: 0 };
      }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiredAuth && !localStorage.getItem("auth")) {
        window.location.replace("/subscription");
    } else {
        next();
    }
});

export default router;